/* App.css */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
}

.container {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  color: #0056b3;
}

.progress-bar {
  width: 100%;
  background-color: #e0e0e0;
  height: 30px;
  border-radius: 15px;
  margin-bottom: 20px;
}

.progress {
  background-color: #007bff;
  height: 30px;
  border-radius: 15px;
}

.donation-summary {
  text-align: center;
  margin-bottom: 20px;
}

.donation-summary p {
  font-size: 18px;
  color: #333;
}

/* Centered main image */
.main-image {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: auto;
}

.title-image {
  display: block;
  margin: 0 auto;
  max-width: 25%;
  height: auto;
}

.donation-options {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
}

.donation-card {
  background-color: #f9f9f9;
  padding: 20px;
  border: 1px solid #ccc;
  width: 30%;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

/* Selected card turns yellow */
.selected {
  background-color: #ffeb3b;
  border-color: #ffeb3b;
}

.donation-card h2 {
  color: #0056b3;
}

.donation-card p {
  margin: 15px 0;
}

/* Custom donation default color as gray */
.custom-donation {
  background-color: #e0e0e0;
}

.selected-amount {
  margin-top: 20px;
  text-align: center;
  font-size: 18px;
  color: #333;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
  margin-top: 30px;
}

.image-grid img {
  width: 200px;
  height: auto;
  border-radius: 10px;
}

footer {
  margin-top: 40px;
  text-align: center;
  color: #999;
}

.personal-info {
  margin-top: 30px;
}

.personal-info h2 {
  text-align: center;
  color: #0056b3;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.form-group {
  width: 100%;
  max-width: 400px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #333;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.submit-button {
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #0056b3;
}

